import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Project from '../Project';
import { featured } from './featured.module.scss';

const FeaturedProjects = () => {
  const data = useStaticQuery(
    graphql`
      query Featured {
        allMarkdownRemark(filter: { frontmatter: { featured: { eq: true } } }) {
          edges {
            node {
              frontmatter {
                demo
                description
                documentation
                npm
                repo
                technologies
                title
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              id
            }
          }
        }
      }
    `
  );

  return (
    <section className={featured}>
      <div>
        <h2>Featured projects</h2>
      </div>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Project
          {...node}
          image={<GatsbyImage image={getImage(node.frontmatter.image)} />}
        />
      ))}
    </section>
  );
};

export default FeaturedProjects;
