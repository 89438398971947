import * as React from 'react';
import Delay from '../components/Delay';
import FeaturedProjects from '../components/FeaturedProjects';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Metadata from '../components/Metadata';
import Summary from '../components/Summary';

const ProjectsPage = () => {
  const metaDescription = 'Check out some of my recent projects';

  return (
    <Layout footerDelay={1500}>
      <Metadata title="Projects" description={metaDescription} />
      <Hero initialDelay={0} />
      <Summary delay={500}>
        <p>
          Check out some of my recent projects. Check out my{' '}
          <a href="https://github.com/iangoodnight" rel="noopener noreferrer">
            Github
          </a>{' '}
          for more.
        </p>
      </Summary>
      <Delay delay={1000}>
        <FeaturedProjects />
      </Delay>
    </Layout>
  );
};

export default ProjectsPage;
