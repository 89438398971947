import * as React from 'react';
import PropTypes from 'prop-types';
import { tagbox } from './tagbox.module.scss';

const Tagbox = ({ tags = [], title = 'Tags' }) => {
  return (
    <div className={tagbox}>
      <h4>{title}</h4>
      {tags.length &&
        tags.map((tag) => (
          <span>
            <pre>{tag}</pre>
          </span>
        ))}
    </div>
  );
};

Tagbox.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default Tagbox;
