import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { link, links } from './projectlinks.module.scss';

const ProjectLinks = ({ demo, documentation, npm, repo }) => {
  return (
    <div className={links}>
      <h4>Links</h4>
      {repo && (
        <a href={repo} rel="noopener noreferrer">
          <div className={link}>
            <StaticImage
              alt="repo"
              height={30}
              layout="fixed"
              objectFit="contain"
              placeholder="blurred"
              src="../../images/github.png"
            />{' '}
            <span>Repo</span>
          </div>
        </a>
      )}
      {documentation && (
        <a href={documentation} rel="noopener noreferrer">
          <div className={link}>
            <StaticImage
              alt="repo"
              height={30}
              layout="fixed"
              objectFit="contain"
              placeholder="blurred"
              src="../../images/web.png"
            />{' '}
            <span>Docs</span>
          </div>
        </a>
      )}
      {demo && (
        <a href={demo} rel="noopener noreferrer">
          <div>
            <StaticImage
              alt="repo"
              className={link}
              height={30}
              layout="fixed"
              objectFit="contain"
              placeholder="blurred"
              src="../../images/external.png"
            />{' '}
            <span>Demo</span>
          </div>
        </a>
      )}
      {npm && (
        <a href={demo} rel="noopener noreferrer">
          <div>
            <StaticImage
              alt="repo"
              className={link}
              height={30}
              layout="fixed"
              objectFit="contain"
              placeholder="blurred"
              src="../../images/npm.png"
            />{' '}
            <span>npm</span>
          </div>
        </a>
      )}
    </div>
  );
};

ProjectLinks.propTypes = {
  demo: PropTypes.string,
  documentation: PropTypes.string,
  npm: PropTypes.string,
  repo: PropTypes.string.isRequired,
};

export default ProjectLinks;
