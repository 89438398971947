import * as React from 'react';
import PropTypes from 'prop-types';
import ProjectLinks from '../ProjectLinks';
import Tagbox from '../Tagbox';
import { card, footer, hero, project, summary } from './project.module.scss';

const Project = ({ frontmatter, id, image }) => {
  const {
    demo,
    description,
    documentation,
    npm,
    repo,
    technologies = [],
    title,
  } = frontmatter;

  return (
    frontmatter && (
      <div className={project} key={id}>
        <div className={card}>
          {title && <h3>{title}</h3>}
          <div className={hero}>{image}</div>
          <div className={summary}>
            <h4>Description</h4>
            <p>{description}</p>
          </div>
          <Tagbox tags={technologies} title="Technologies" />
        </div>
        <div className={footer}>
          <ProjectLinks
            demo={demo}
            documentation={documentation}
            npm={npm}
            repo={repo}
          />
        </div>
      </div>
    )
  );
};

Project.propTypes = {
  frontmatter: PropTypes.shape({
    demo: PropTypes.string,
    description: PropTypes.string,
    documentation: PropTypes.string,
    nom: PropTypes.string,
    repo: PropTypes.string,
    technologies: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default Project;
