import * as React from 'react';
import PropTypes from 'prop-types';
import Delay from '../Delay';
import { summary } from './summary.module.scss';

const Summary = ({ children, delay = 0 }) => {
  return (
    <section className={summary}>
      <Delay delay={delay}>{children}</Delay>
    </section>
  );
};

Summary.propTypes = {
  children: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  delay: PropTypes.number,
};

export default Summary;
